import { useRef } from 'react';
import './App.css';
import { Button, Stack, SimpleGrid } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import galley_hot_plate_1 from './assets/galley_hot_plate_1.png';
import galley_hot_plate_2 from './assets/galley_hot_plate_2.png';
import galley_hot_plate_3 from './assets/galley_hot_plate_3.png';
import galley_hot_plate_4 from './assets/galley_hot_plate_4.png';
import cooking_range_1 from './assets/cooking_range_1.png';
import cooking_range_2 from './assets/cooking_range_2.png';
import cooking_range_3 from './assets/cooking_range_3.png';
import calorifier_1 from './assets/calorifier_1.png';
import calorifier_2 from './assets/calorifier_2.png';
import calorifier_3 from './assets/calorifier_3.png';
import boiler_1 from './assets/boiler_1.png';
import boiler_2 from './assets/boiler_2.png';
import hotplate_1 from './assets/hotplate_1.png';
import hotplate_2 from './assets/hotplate_2.png';
import hotplate_3 from './assets/hotplate_3.png';
import hotplate_4 from './assets/hotplate_4.png';
import calorifier_heater_3kw from './assets/calorifier_heater_3kw.png';
import calorifier_heater_5kw from './assets/calorifier_heater_5kw.png';
import calorifier_heater_6kw from './assets/calorifier_heater_6kw.png';
import calorifier_heater_9kw from './assets/calorifier_heater_9kw.png';

import oceansparkle from './assets/oceansparkle.png';
import comacoe from './assets/comacoe.png';
import essfour from './assets/essfour.png';
import shoft from './assets/shoft.png';
import marine_electricals from './assets/marine_electricals.png';
import samson from './assets/samson.png';
import modest from './assets/modest.png';
import titagarh from './assets/titagarh.png';
import chowgule from './assets/chowgule.png';

import logo from './assets/logo.png';

function App() {
  const productRef = useRef(null);
  const customersRef = useRef(null);
  const contactsRef = useRef(null);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p className='header_title' style={{ fontWeight: 'bold' }}>Rajeshree Enterprize</p>
        <p style={{ marginBottom: 20 }}>Galley Hot Plate, Calorifer, Hydrophore, Hot Water Boiler, Etc</p>
        <Stack spacing={4} direction="row">
          <Button size='lg' colorScheme='green' onClick={() => productRef.current.scrollIntoView()}>Our Products</Button>
          <Button size='lg' colorScheme='green' onClick={() => contactsRef.current.scrollIntoView()}>Contact Us</Button>
        </Stack>
      </header>
      <div ref={productRef} className='products_container'>
        <p style={{ fontSize: 32, marginBottom: 20, fontWeight: 'bold' }}>Our Products</p>
        <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
        <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Hot Plates and Heating Elements</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={hotplate_1} alt="img" />
                <p className='legend'>Hot Plate</p>
              </div>
              <div>
                <img src={hotplate_2} alt="img" />
                <p className='legend'>Hot Plate</p>
              </div>
              <div>
                <img src={hotplate_3} alt="img" />
                <p className='legend'>Hot Plate</p>
              </div>
              <div>
                <img src={hotplate_4} alt="img" />
                <p className='legend'>Hot Plate with Heating Element</p>
              </div>
            </Carousel>
          </div>
          <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Calorifer Heaters</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={calorifier_heater_3kw} alt="img" />
                <p className='legend'>Calorifier Heater 3 KW</p>
              </div>
              <div>
                <img src={calorifier_heater_5kw} alt="img" />
                <p className='legend'>Calorifer Heater 5 KW</p>
              </div>
              <div>
                <img src={calorifier_heater_6kw} alt="img" />
                <p className='legend'>Calorifer Heater 6 KW</p>
              </div>
              <div>
                <img src={calorifier_heater_9kw} alt="img" />
                <p className='legend'>Calorifier Heater 9 KW</p>
              </div>
            </Carousel>
          </div>
          <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Galley Top Hubs</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={galley_hot_plate_1} alt="img" />
                <p className='legend'>Galley Top Hub, Single Hot Plate</p>
              </div>
              <div>
                <img src={galley_hot_plate_2} alt="img" />
                <p className='legend'>Galley Top Hub, Two Hot Plates</p>
              </div>
              <div>
                <img src={galley_hot_plate_3} alt="img" />
                <p className='legend'>Galley Top Hub, Three Hot Plates</p>
              </div>
              <div>
                <img src={galley_hot_plate_4} alt="img" />
                <p className='legend'>Galley Top Hub, Four Hot Plates</p>
              </div>
            </Carousel>
          </div>
          <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Galley Cooking Ranges with Oven</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={cooking_range_1} alt="img" />
                <p className='legend'>Galley Cooking Range with Oven, Rectangular Galley</p>
              </div>
              <div>
                <img src={cooking_range_2} alt="img" />
                <p className='legend'>Galley Cooking Range with Oven, Square Galley</p>
              </div>
              <div>
                <img src={cooking_range_3} alt="img" />
                <p className='legend'>Galley Cooking Range with Oven, Square Galley</p>
              </div>
            </Carousel>
          </div>
          <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Hot Water Calorifiers</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={calorifier_1} alt="img" />
                <p className='legend'>Hot Water Calorifier</p>
              </div>
              <div>
                <img src={calorifier_2} alt="img" />
                <p className='legend'>Control Panel of Calorifier</p>
              </div>
              <div>
                <img src={calorifier_3} alt="img" />
                <p className='legend'>Calorifier and Hydrophore Tanks at Modest Shipyard</p>
              </div>
            </Carousel>
          </div>
          <div>
            <p style={{ fontSize: 26, marginBottom: 20 }}>Hot Water Boilers</p>
            <Carousel
              dynamicHeight={true}
              width={400}
              autoPlay={true}
              infiniteLoop={true}
            >
              <div>
                <img src={boiler_1} alt="img" />
                <p className='legend'>3 KW / 220 V / 1 Phase / 50 Hz</p>
              </div>
              <div>
                <img src={boiler_2} alt="img" />
                <p className='legend'>4.5 KW / 440 V / 3 Phase / 60 Hz</p>
              </div>
            </Carousel>
          </div>
        </SimpleGrid>
      </div>
      <div ref={customersRef} className='customers_container'>
        <p style={{ fontSize: 32, marginBottom: 20, fontWeight: 'bold' }}>Our Valuable Customers</p>
        <SimpleGrid columns={[2, 2, 3]} spacing={10}>
          <img src={oceansparkle} alt="img" className='customerLogo' />
          <img src={comacoe} alt="img" className='customerLogo' />
          <img src={essfour} alt="img" className='customerLogo' />
          <img src={shoft} alt="img" className='customerLogo' />
          <img src={marine_electricals} alt="img" className='customerLogo' />
          <img src={samson} alt="img" className='customerLogo' />
          <img src={modest} alt="img" className='customerLogo' />
          <img src={titagarh} alt="img" className='customerLogo' />
          <img src={chowgule} alt="img" className='customerLogo' />
        </SimpleGrid>
      </div>
      <div ref={contactsRef} className='contact_us'>
        <p style={{ fontSize: 32, marginBottom: 20, fontWeight: 'bold' }}>Get in Touch</p>
        <SimpleGrid columns={3} spacing={10}>
          <p style={{ fontSize: 18 }}>
            <b>Email:</b><br />
            <a href='mailto:rmnazare@gmail.com'>rmnazare@gmail.com</a> <br />
            <a href='mailto:rmnazare@rediffmail.com'>rmnazare@rediffmail.com</a> <br />
          </p>
          <p style={{ fontSize: 18 }}>
            <b>Phone:</b><br />
            +91 9870196369 <br />
            +91 9664990715
          </p>
          <p style={{ fontSize: 18 }}>
            <b>Office Address:</b><br />
            13 Namdeep,<br />
            Nath Pai Nagar,<br />
            90 Feet Road,<br />
            Ghatkopar EAST,<br />
            Mumbai - 400077
          </p>
        </SimpleGrid>
      </div>
    </div>
  );
}

export default App;
